<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Contactformulier</b-card-title>
                <b-form v-if="state === 'not-submitted'">
                
                    <b-card-text>
                        <p>Hier kunt hier contact opnemen met Ridder en de Prins door onderstaand formulier in te vullen. </p>
                    </b-card-text>
                    <b-form-group label-cols-sm="3" label="* Onderwerp:" label-align-sm="right" label-for="onderwerp">
                        <b-form-select id="onderwerp" v-model="$v.form.onderwerp.$model" required placeholder="Kies een onderwerp" :options="['Sinterklaasshow',  'Huisbezoek', 'Theatervoorstelling', 'Overig']"> </b-form-select>
                        <p v-if="validateErrors">
                            <span class="error" v-if="!$v.form.onderwerp.required">Kies een optie</span>
                        </p>
                    </b-form-group>
                    <b-form-group label-cols-sm="3" label="* Naam:" label-align-sm="right" label-for="naam">
                        <b-form-input id="naam" v-model="$v.form.naam.$model" required placeholder="Uw naam"> </b-form-input>
                        <p v-if="validateErrors">
                            <span class="error" v-if="!$v.form.naam.required">Vul een naam in</span>
                        </p>
                    </b-form-group>
                    <b-form-group label-cols-sm="3" label="Bedrijfsnaam:" label-align-sm="right" label-for="bedrijfsnaam">
                        <b-form-input id="bedrijfsnaam" v-model="form.bedrijfsnaam" placeholder="Uw bedrijfsnaam"> </b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="3" label="* Email:" label-align-sm="right" label-for="email">
                        <b-form-input id="email" v-model="$v.form.email.$model" required placeholder="Uw email"> </b-form-input>
                        <p v-if="validateErrors">
                            <span class="error" v-if="!$v.form.email.required">Voer een email in </span>
                            <span class="error" v-if="!$v.form.email.email">Voer een geldig email in </span>
                        </p>
                    </b-form-group>
                    <b-form-group label-cols-sm="3" label="Telefoonnummer:" label-align-sm="right" label-for="telefoonnummer">
                        <b-form-input id="telefoonnummer" v-model="form.telefoonnummer" required placeholder="Uw telefoonnummer"> </b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="3" label="Adres:" label-align-sm="right" label-for="adres">
                        <b-form-input id="adres" v-model="form.adres" placeholder="Uw adres"> </b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="3" label="Woonplaats:" label-align-sm="right" label-for="woonplaats">
                        <b-form-input id="woonplaats" v-model="form.woonplaats" placeholder="Uw woonplaats"> </b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-sm="3" label="Datum:" label-align-sm="right" label-for="datum">
                        <b-form-datepicker id="datum" v-model="form.datum" required locale="nl" placeholder="Gewenste datum"> </b-form-datepicker>
                    </b-form-group>
                    <b-form-group label-cols-sm="3" label="* Uw vraag:" label-align-sm="right" label-for="vraag">
                        <b-form-textarea id="vraag" v-model="form.vraag" required placeholder="Uw vraag"> </b-form-textarea>
                          <p v-if="validateErrors">
                           <span class="error" v-if="!$v.form.vraag.required">Wat is uw vraag? </span>
                         </p>
                    </b-form-group>
                    <b-row>
                        <b-col sm="3">
                        </b-col>
                        <b-col sm="9">
                            <small><p class="float-right">
                                 De met * aangeduide velden zijn verplicht
                                 </p></small>
                        </b-col>
                        <b-col sm="3">
                        </b-col>
                        <b-col sm="9">
                        
                            <vue-recaptcha class="float-right" sitekey="6Lc9e6YZAAAAAC7Dt7dMGXv5pBc4imJUpGY7qiax" :loadRecaptchaScript="true" v-on:verify="captchaVerify" v-on:error="captchaError" v-on:expired="captchaExpired">
                            </vue-recaptcha>
                        
                        </b-col>
                        <b-col sm="3">
                        </b-col>
                        <b-col sm="9">
                            <b-button class="float-right" @click.prevent="onSubmit()" variant="primary" :disabled="!canSubmit">Verzend</b-button>
                        </b-col>
                    </b-row>
                </b-form>
                <b-alert :show="state !== 'not-submitted'" :variant="alert.variant">
                    <h4 class="alert-heading">{{ alert.title }}</h4>
                    <p>{{ alert.message}}</p>
                </b-alert>
            </b-card>
        </b-col>
    </b-row>
</template>


<script scope>
    import VueRecaptcha from 'vue-recaptcha';
    import {
        required,
        email
    } from 'vuelidate/lib/validators'
    export default {
        name: 'Contact',
        components: {
            VueRecaptcha
        },
        data() {
            return {
                form: {
                    onderwerp: '',
                    naam: '',
                    bedrijfsnaam: '',
                    email: '',
                    telefoonnummer: '',
                    adres: '',
                    woonplaats: '',
                    datum: '',
                    vraag: '',
                    captcha: ''
                },
                show: true,
                canSubmit: false,
                state: 'not-submitted',
                alert: {
                    variant: "",
                    title: "",
                    message: ""
                },
                validateErrors: false
            }
        },
        validations: {
            form: {
                naam: {
                    required
                },
                onderwerp: {
                    required
                },
                email: {
                    email,
                    required
                },
                vraag: {
                    required
                }
            }
        },
        methods: {
            onSubmit() {
                if (this.$v.$invalid) {
                    this.validateErrors = true
                } else {
                    this.$http.post('https://api.ridderendeprins.nl/web/contactform/', this.form)
                        .then((result => {
                            if (result.status === 200) {
                                this.alert.variant = "primary"
                                this.alert.title = "Bedankt"
                                this.alert.message = 'Uw aanvraag is in goede orde ontvangen en zal zo spoedig mogelijk worden beantwoord. Met vriendelijke groet, Ridder en de Prins Entertainment'
                                this.state = 'submitted';
                            } else {
                                this.alert.variant = "danger"
                                this.alert.title = "Oeps"
                                this.alert.message = "Uhh"
                                this.state = 'failed';
                            }
                            window.scrollTo(0, 0);
                        }))
                        .catch((error) => {
                            this.alert.variant = "danger"
                            this.alert.title = "Oeps"
                            this.alert.message = error
                            this.state = 'error';
                            console.log(error)
                        })
                }
            },
            captchaError() {
                this.alert.variant = "warning"
                this.alert.title = "Helaas"
                this.alert.message = "Door een technische storing is het niet mogelijk om dit formulier te " +
                    "gebruiken. Probeer het later nog een keer. Onze excuses voor het ongemak."
                this.state = 'error';
                window.scrollTo(0, 0);
            },
            captchaVerify(response) {
                // We got the captcha response
                console.log("Captcha verified: " + JSON.stringify(response))
                this.form.captcha = response
                this.canSubmit = true
            },
            captchaExpired() {
                this.form.captcha = ''
                this.canSubmit = false
            }
        }
    }
</script>

<style>
    .error {
        color: red;
    }
</style>